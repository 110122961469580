.label {
   text-align: left;
   font-size: 18px;
   color: #4B4B4B;
   font-weight: 500;
   width: 100%;
}

.field {
   margin: 10px 0px;
   background: #FFF;
   border: 0.20000000298023224px solid #707070;
   border-radius: 8px;
   height: 55px;
   outline: none;
   box-shadow: none;
   padding: 10px 10px;
   width: 100%;
   font: normal normal medium 20px/47px;
   font-weight: 500;
   color: #4B4B4B;
}

.field::placeholder {
   color: #4B4B4B;
}

.miField {
   margin: 10px 0 !important;

   input::placeholder {
      color: #4B4B4B;
   }

   fieldset {
      border: 0.20000000298023224px solid #707070 !important;
      border-radius: 8px;
      height: 61px;
      font: normal normal medium 20px/47px;
      font-weight: 500;
      color: #4B4B4B;
   }
}

.messages {
   text-align: left;
   font-size: 11px;
   color: #A5A5A5;
   width: 100%;
   font-size: 14px;
   opacity: 1;
   font-weight: 500;
}

.field div,
.field div:hover,
.field div:focus,
.field div:active {
   border: none !important;

   outline: none !important;
   border-color: #fff !important;
   box-shadow: none;
}

.submit {
   border: solid #1F428A 1px;
   height: 55px;
   outline: #1F428A;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 10px;
   background: #1F428A 0% 0% no-repeat padding-box;
   border-radius: 8px;
   opacity: 1;
   width: 38%;
   font: normal normal medium 20px/47px;
   color: #FFFFFF;
   font-weight: 500;
}

.submit22 {
   border: solid #949494 1px;
   height: 55px;
   outline: #949494;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 10px;
   background: #fff 0% 0% no-repeat padding-box;
   border-radius: 8px;
   opacity: 1;
   width: 38%;
   font: normal normal medium 20px/47px;
   color: #949494;
   margin-left: 15px;
   font-weight: 500;
}

.formGroup {
   width: 100%;
   display: flex;
   flex-direction: column;
   margin: 0px;
   justify-content: center;
   align-items: center;
   height: 100%;
}

.formGroup2 {
   width: 100%;
   display: flex;
   flex-direction: row;
   margin: 0px;
   padding: 15px;
   align-items: center;
   height: 100%;
}

.filterIcon {
   width: 55px;
   height: 55px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   border: 0.699999988079071px solid #707070;
   display: flex;
   border-radius: 8px;
   margin: 0px 0px 0px 15px;
   justify-content: center;
   align-items: center;
}

.rowBox {
   margin: 0px;
   height: 100%;
}

.rowBox2 {
   margin: 0px;
   height: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.colP15 {
   padding: 10px 0px;
}