.main {
  .header {
    margin-top: 48px;

    h1 {
      font-size: 30px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      padding: 0;
      margin: 0;
    }
  }
}

.formContainer {
  // margin: 40px 0 105px 0;
  padding: 32px 16px;

  .formDiv {
    row-gap: 40px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > label {
        font-size: 18px;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #ff0000;
          margin-right: 5px;
          font-size: 14px;
        }
      }

      .inputField {
        > div,
        > div:hover,
        > div:focus,
        > div:active {
          // border: none !important;

          // outline: none !important;
          // border-color: #fff !important;
          // box-shadow: none;
          // height: 55px;
          padding: 9px;
        }

        // background: #fff;
        // border: 0.1px solid rgba(0, 0, 0, 0.25);
        // border-radius: 8px;
        // // height: 55px;
        // outline: none;
        // box-shadow: none;
        // padding: 10px 10px;
        // width: 100%;
        // font: normal normal medium 20px/47px;
        // font-weight: 500;
        // color: #4b4b4b;

        // &::placeholder {
        //   color: #4b4b4b;
        // }
      }

      .error {
        > div {
          box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0.5) !important;
        }
      }

      .btnDiv {
        display: flex;
        align-items: center;
        gap: 14px;

        button {
          flex: 1;
          border: none;
          border: 1px solid #707070 !important;
          outline: none;
          background: transparent;
          padding: 16px;
          border-radius: 8px;
        }

        .searchBtn {
          background: #1f428a;
          color: #ffffff;
        }
      }
    }
  }
}

.resultMain {
  .header {
    margin-top: 48px;
    margin-bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 40px;
      font-weight: 600;
    }

    p {
      font-size: 22px;
      padding: 0;
      margin: 0;
    }

    button,
    a {
      padding: 16px 56px;
      background-color: #1f428a;
      border: 1px solid  #1f428a;
      border-radius: 8px;
      color: #ffffff;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      text-decoration: none;
    }

    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: start;

      h1 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }

      .headerBtn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        button,
        a {
          padding: 16px;
          font-size: 14px;
        }
      }
    }
  }
}

.formContainer {
  padding: 24px 16px;
}

.banner {
  background-color: #f8ffec;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #707070;
  margin-bottom: 32px;
  width: 100%;

  p {
    color: #4b4b4b;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    padding: 0;
    margin: 0;

    svg {
      font-size: 22px;
    }
  }

  button,
  a {
    background-color: #7a9d3e;
    border-radius: 8px;
    font-size: 16px;
    padding: 14px 32px;
    border: 1px solid #7a9d3e;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    gap: 6px;

    svg {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 0.5rem;
    text-align: center;

    p {
      font-size: 16px;
      align-items: flex-start;
      gap: 0px;
    }

    button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}

.TableContainer {
  border-radius: 0px !important;
  box-shadow: none !important;

  table {
    thead {
      background: #e3ecff;

      tr {
        th {
          color: #1f428a;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #ececec;
          font-size: 16px;
          padding: 10px 16px;
          // font-weight: 500;
        }
      }
    }
  }
}

// components/MedicareTable.module.scss
.mainTable {
  margin-bottom: 66px;

  .tableContainer {
    // padding: 20px;
    max-width: 100%;
    overflow-x: auto;
    box-shadow: none;

    .table {
      width: 100%;
      border-collapse: collapse;
      // table-layout: fixed; // Ensures uniform column widths
      // for the entire table first column width is 25% and the rest two columns are 50% of remaining 75%
      text-align: left;

      thead {
        tr {
          background-color: #e3ecff;
        }
      }

      th,
      td {
        padding: 15px;
        width: 25%;
        border: none;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        font-size: 16px;
      }

      th {
        font-weight: 600;
      }

      td {
        vertical-align: top;
      }

      tbody {
        tr {
          td {
            &:nth-child(1) {
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.enrollButton {
  background-color: #7a9d3e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;

  &:hover {
    background-color: #7a9d3e;
  }
}

.contactBanner {
  background-color: #f8ffec;
  font-size: 18px;
  padding: 8px;
  border-radius: 12px;
  font-weight: 500;
  margin-bottom: 93px;
}

.noData {
  font-size: 18px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
}
