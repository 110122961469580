.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #f3f7ff;
  position: relative;
}
.main2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #f9f9f9;
  position: relative;
}
