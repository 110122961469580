.searchContainer {
   border: solid #fff 1px;
   padding: 15px;
   background: #fff;
   border-radius: 15px;
   box-shadow: 1px 0px 3px 1px rgba(243, 247, 255, 0.92);
}

.heading {
   font: normal normal 600 40px/47px;
   letter-spacing: 0px;
   color: #000000;
   opacity: 1;
   font-weight: 700;
}

.tabfilter,
.tabfilter:hover,
.tabfilter:focus,
.tabfilter:active {
   border: none !important;
   border-bottom: none !important;
   text-align: center;
   background: #fff !important;
   font: normal normal 600 22px/47px;
   letter-spacing: 0px;
   color: #7b7b7b;
   font-size: 20px;
   width: fit-content;
   font-weight: 600;
   text-wrap: nowrap !important;
   outline: none !important;
   outline: none;
   padding: 15px !important;
   opacity: 1;
   /* border-top: solid #7b7b7b 1px !important; */
}

.tabFilterActive,
.tabFilterActive:hover,
.tabFilterActive:focus,
.tabFilterActive:active {
   border: none !important;
   border-bottom: solid #1f428a 5px !important;
   text-align: center;
   background: #fff !important;
   font: normal normal 600 22px/47px;
   letter-spacing: 0px;
   color: #1f428a;
   font-size: 20px;
   width: fit-content;
   font-weight: 600;
   text-wrap: nowrap !important;
   outline: none;
   opacity: 1;
   padding: 15px !important;
}

.paneltabfilter {
   border: none !important;
   border: solid #7b7b7b 3px !important;
   margin-top: 0.5px;
   padding: 15px;
   display: none !important;
   background: #fff;
}

.paneltabfilter2 {
   border: none !important;
   /* border-top: solid #7B7B7B 1px !important; */
   margin-top: 0px !important;
   padding: 15px;
   background: #fff;
}

@media screen and (max-width: 1200px) {
   .formContainer {
      justify-content: flex-start !important;
   }
}
